<template>
  <v-dialog
      v-model="dialog"
      max-width="600px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
          color="primary"
          dark
          v-bind="attrs"
          v-on="on"
          @click="createMcq"
      >
        Create new MCQ
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="text-center">
        <span class="headline">MCQ Details</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <form @submit.prevent="takeAction">
            <v-row>
              <!--Question-->
              <v-col
                  cols="12"
              >
                <tiptap-vuetify
                    v-model="question"
                    :extensions="extensions"
                    placeholder="Enter question"
                >
                </tiptap-vuetify>
              </v-col>

              <!--Question Photo-->
              <v-col
                  cols="12"
              >
                <v-file-input
                    chips
                    label="Choose question photo"
                    v-model="question_photo"
                ></v-file-input>
              </v-col>

              <!--A-->
              <v-col
                  cols="12"
                  sm="12"
                  md="6"
              >
                <v-text-field
                    v-model="a"
                    label="Option A"
                    required
                ></v-text-field>
              </v-col>

              <!--B-->
              <v-col
                  cols="12"
                  sm="12"
                  md="6"
              >
                <v-text-field
                    v-model="b"
                    label="Option B"
                    required
                ></v-text-field>
              </v-col>

              <!--C-->
              <v-col
                  cols="12"
                  sm="12"
                  md="6"
              >
                <v-text-field
                    v-model="c"
                    label="Option C"
                    required
                ></v-text-field>
              </v-col>

              <!--D-->
              <v-col
                  cols="12"
                  sm="12"
                  md="6"
              >
                <v-text-field
                    v-model="d"
                    label="Option D"
                    required
                ></v-text-field>
              </v-col>

              <!--E-->
              <v-col
                  cols="12"
                  sm="12"
                  md="6"
              >
                <v-text-field
                    v-model="e"
                    label="Option E"
                ></v-text-field>
              </v-col>

              <!--Answer-->
              <v-col
                  class="d-flex"
                  cols="12"
              >
                <v-select
                    :items="answerOptions"
                    label="Select answer"
                    v-model="answer"
                ></v-select>
              </v-col>

              <!--Answer Description-->
              <v-col
                  cols="12"
              >
                <tiptap-vuetify
                    v-model="answer_description"
                    :extensions="extensions"
                    placeholder="Enter answer description"
                >
                </tiptap-vuetify>
              </v-col>

              <!--Answer Photo-->
              <v-col
                  cols="12"
              >
                <v-file-input
                    chips
                    label="Choose answer photo"
                    v-model="answer_photo"
                ></v-file-input>
              </v-col>


              <v-btn
                  class="mr-4"
                  type="submit"
                  :loading="loading"
              >
                {{ editMode ? 'update' : 'submit' }}
              </v-btn>
              <v-btn @click="clear">
                clear
              </v-btn>
            </v-row>
          </form>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            color="blue darken-1"
            text
            @click="dialog = false"
        >
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import axios from "axios";
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Code,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  Blockquote,
  HardBreak,
  HorizontalRule,
  History
} from 'tiptap-vuetify'

export default {
  props: ['section', 'mcq'],
  components: {
    TiptapVuetify,
  },
  data() {
    return {
      showEditButton: true,
      loading: false,
      mcqs: [],
      selectedMcq: null,
      editMode: false,
      dialog: false,
      question: '',
      a: '',
      b: '',
      c: '',
      d: '',
      e: '',
      answer: '',
      answer_description: '',
      question_photo: null,
      answer_photo: null,
      snackbar: false,
      text: '',
      color: 'blue',
      extensions: [
        History,
        Blockquote,
        Link,
        Underline,
        Strike,
        Italic,
        ListItem,
        BulletList,
        OrderedList,
        [Heading, {
          options: {
            levels: [1, 2, 3]
          }
        }],
        Bold,
        Link,
        Code,
        HorizontalRule,
        Paragraph,
        HardBreak
      ],
    }
  },
  computed: {
    examId: function () {
      return this.$route.params.examId;
    },
    form: function () {
      let formData = new FormData();
      formData.append('section_id', this.section.id);
      if (this.editMode) {
        formData.append('_method', 'PUT');
      }
      if (this.question) {
        formData.append('question', this.question);
      }
      if (this.a) {
        formData.append('a', this.a);
      }
      if (this.b) {
        formData.append('b', this.b);
      }
      if (this.c) {
        formData.append('c', this.c);
      }
      if (this.d) {
        formData.append('d', this.d);
      }
      if (this.e) {
        formData.append('e', this.e)
      }
      if (this.answer) {
        formData.append('answer', this.answer);
      }
      if (this.answer_description) {
        formData.append('answer_description', this.answer_description);
      }
      if (this.question_photo) {
        formData.append('question_photo', this.question_photo);
      }
      if (this.answer_photo) {
        formData.append('answer_photo', this.answer_photo);
      }
      return formData;
    },
    answerOptions: function () {
      let options = [this.a, this.b, this.c, this.d, this.e];
      return options.filter(function (el) {
        return el !== '';
      });
    }
  },
  methods: {
    takeAction() {
      this.editMode ? this.update() : this.submit();
    },
    createMcq() {
      this.editMode = false;
      this.selectedMcq = null;
      this.question = '';
      this.a = '';
      this.b = '';
      this.c = '';
      this.d = '';
      this.e = '';
      this.answer = '';
      this.answer_description = '';
      this.question_photo = null;
      this.answer_photo = null;
    },
    edit(mcq) {
      this.selectedMcq = mcq;
      this.dialog = true;
      this.editMode = true;
      this.question = mcq.question;
      this.a = mcq.a;
      this.b = mcq.b;
      this.c = mcq.c;
      this.d = mcq.d;
      this.e = mcq.e ? mcq.e : ''
      this.answer = mcq.answer;
      this.answer_description = mcq.answer_description;
      this.question_photo = null;
      this.answer_photo = null;
    },
    submit() {
      const url = 'admin/mcqs';
      this.loading = true;
      axios.post(url, this.form).then(() => {
        this.loading = false;
        this.clear();
        this.color = 'green';
        this.snackbar = true;
        this.text = 'New mcq created';
        this.dialog = false;
        this.$emit('updated')
      })
    },
    update() {
      const url = 'admin/mcqs/' + this.selectedMcq.id;
      this.loading = true;
      axios.post(url, this.form).then(() => {
        this.loading = false;
        this.clear();
        this.color = 'green';
        this.snackbar = true;
        this.text = 'Mcq updated';
        this.$emit('updated');
        this.dialog = false;
      })
    },
    clear() {
      this.editMode = false;
      this.question = '';
      this.a = '';
      this.b = '';
      this.c = '';
      this.d = '';
      this.e = ''
      this.answer = '';
      this.answer_description = '';
      this.question_photo = null;
      this.answer_photo = null;
      this.selectedMcq = null;
      this.$refs.observer.reset();
    },
  },
  watch: {
    mcq() {
      if (this.mcq) {
        this.edit(this.mcq)
      }
    },
    dialog() {
      if (!this.dialog) {
        this.$emit('closed')
      }
    }
  }
}
</script>