<template>
  <div class="text-center">
    <v-dialog
        v-model="dialog"
        width="500"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
            dark
            v-bind="attrs"
            v-on="on"
            icon
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="headline grey lighten-2">
          Insert new section
        </v-card-title>

        <v-card-text>
          <v-form @submit.prevent="submit">
            <v-switch v-model="required" label="Required"></v-switch>
            <v-text-field
                label="Section name"
                v-model="name"
            ></v-text-field>
            <v-btn type="submit" block color="primary">Save</v-btn>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import axios from "axios";

export default {
  props: ['exam'],
  data() {
    return {
      dialog: false,
      name: '',
      required: false,
    }
  },
  computed: {
    form() {
      let formData = new FormData()
      if (this.exam) {
        formData.append('exam_id', this.exam.id)
      }
      formData.append('name', this.name)
      formData.append('required', this.required ? 1 : 0)
      return formData
    }
  },
  methods: {
    submit() {
      const url = 'admin/sections'
      axios.post(url, this.form).then(() => {
        this.$emit('updated')
        this.dialog = false
        this.clear()
      }).catch(() => {

      })
    },
    clear() {
      this.name = ''
      this.required = false
    }
  }
}
</script>