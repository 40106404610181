<template>
  <v-card elevation="0">
    <v-card-text>
      <edit-exam v-if="!showEditButton" :course="course" :exam-id="videoExamId || examId" :video-id="videoId"></edit-exam>
    </v-card-text>
    <v-toolbar
        dark
    >
      <v-spacer></v-spacer>

      <insert-section @updated="initialize" :exam="exam"></insert-section>
      <v-btn
          v-if="showEditButton"
          @click="showEditButton = false"
          class="mx-2"
          dark
          icon
          small
      >
        <v-icon dark>
          mdi-pencil
        </v-icon>
      </v-btn>
      <v-btn
          v-else
          @click="showEditButton = true"
          class="mx-2"
          dark
          icon
          color="red"
          small
      >
        <v-icon dark>
          mdi-close
        </v-icon>
      </v-btn>

      <template v-slot:extension>
        <v-tabs
            v-model="tab"
        >
          <v-tabs-slider color="yellow"></v-tabs-slider>

          <v-tab
              v-for="section in sections"
              :key="section.id"
          >
            <div :class="section.required ? 'red--text' : 'green--text'">{{ section.name }}</div>
          </v-tab>
        </v-tabs>
      </template>
    </v-toolbar>
    <v-tabs-items v-model="tab">
      <v-tab-item
          v-for="section in sections"
          :key="section.id+section.name"
      >
        <v-card>
          <v-card-actions>
            <create-mcq :mcq="selectedMcq" @closed="selectedMcq = null" :section="section"
                        @updated="initialize"></create-mcq>
            <import-excel :section_id='section.id' @updated="initialize"></import-excel>
            <export-mcq :section_id="section.id" :mcqs="section.mcqs"></export-mcq>
            <v-spacer></v-spacer>
            <edit-section @updated="initialize" :section="section"></edit-section>
            <v-btn icon color="red" @click="deleteItem(section)">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </v-card-actions>
          <v-card-text>
            <v-col cols="12">
              <show-mcq @updated="initialize" v-for="(mcq,index) in section.mcqs" :index="index" :mcq="mcq"
                        :key="mcq.id" @editMcq="edit">
              </show-mcq>
            </v-col>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>
<script>

import InsertSection from "./InsertSection";
import axios from "axios";
import EditSection from "./EditSection";
import Swal from "sweetalert2";
import CreateMcq from "./CreateMcq";
import ShowMcq from "./ShowMcq";
import EditExam from "./EditExam";
import ImportExcel from "./ImportExcel";
import ExportMcq from "./ExportMcq";

export default {
  props: ['course','videoExamId','videoId'],
  components: {
    ExportMcq,
    ImportExcel,
    EditExam,
    ShowMcq,
    CreateMcq,
    EditSection,
    InsertSection,
  },
  data() {
    return {
      showEditButton: true,
      tab: null,
      exam: '',
      sections: [],
      text: '',
      selectedMcq: null,
    }
  },
  computed:{
    examId: function () {
      return this.videoExamId ? this.videoExamId : this.$route.params.examId;
    },
  },
  methods: {
    initialize() {
      const url = 'admin/sections?id=' + this.$route.params.examId
      axios.get(url).then((response) => {
        this.exam = response.data
        this.sections = response.data.sections
      })
    },
    edit(mcq) {
      this.selectedMcq = mcq
    },
    deleteItem(section) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes'
      }).then((result) => {
        if (result.isConfirmed) {
          const url = 'admin/sections/' + section.id
          axios.delete(url).then(() => {
            this.initialize()
            Swal.fire(
                'Deleted!',
                '',
                'success'
            )
          }).catch(() => {
            Swal.fire(
                'Failed!',
                'Something went wrong, try again',
                'warning'
            )
          })
        }
      })
    }
  },
  created() {
    this.initialize()
  }
}
</script>